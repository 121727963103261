<template>
  <div >

    <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
    >
      <el-form-item label="发布时间">
        <el-date-picker
            v-model="dataForm.timeArr"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()" type="primary">查询</el-button>
        <el-button @click="refresh()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button @click="exportList()" type="primary">导出</el-button>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        style="width: 100%"
    >
      <el-table-column
          prop="statDate"
          header-align="center"
          align="center"
          label="时间"
          width="180px"
      >
      </el-table-column>
      <el-table-column
          prop="prompt"
          header-align="center"
          align="center"
          label="生成prompt内容"
          width="180px"
      >
      </el-table-column>
      <el-table-column
          prop="totalPush"
          header-align="center"
          align="center"
          label="推送数量"
          width="180px"
      >
      </el-table-column>
      <el-table-column
          prop="totalClick"
          header-align="center"
          align="center"
          label="点击数量"
      >
      </el-table-column>


      <el-table-column
          prop="clickRate"
          header-align="center"
          align="center"
          label="push点击率"
      >
        <template slot-scope="scope">
          {{scope.row.clickRate}}%
        </template>
      </el-table-column>
      <el-table-column
          prop="updatePersonName"
          header-align="center"
          align="center"
          label="操作人"
      >
      </el-table-column>

    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

  </div>
</template>

<script>

export default {
  data() {
    return {
      dataForm: {
        operationPerson: '',
        timeArr:[]
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
      }
    }
  },
  components: {
  },
  computed: {
    timeDefault() {
      var date = new Date();
      return date.getFullYear() + "-" + (date.getMonth() < 10 ? '0':'') + (date.getMonth() + 1) + "-" + (date.getDate());
    }
  },
  mounted() {
    // 初始化查询，默认为当天
    this.dataForm.timeArr = [this.timeDefault + ' 00:00:00', this.timeDefault + ' 23:59:59']
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.dataForm = {
        operationPerson: '',
        timeArr:[]
      }
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/officialPushTask/detail/page'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          pushTimeStart: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
          pushTimeEnd: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.$message.error(data.msg)
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    exportList(){
      const exportData = {
        ...this.dataForm,
        createTimeStart: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
        createTimeEnd: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
          this.$http.adornUrl('/admin/virtual/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
          "_blank"
      );
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header{
  padding: 1vh 1vw 0 1vw;
}
::v-deep .el-dialog__body{
  padding: 20px 20px;
}
</style>
